import GetProducts from './GetProducts'

function Layout() {
    return (
        <section className="react-content">
            <GetProducts brand="Gallant" />
        </section>
    )
}

export default Layout